<template>
	<section class="section10 text-center">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<h2 class="darkblueTxt">{{ $t('main.select_inhaler.heading') }}</h2>
					<p class="lightblueTxt m-0">{{ $t('main.select_inhaler.sub_heading') }}</p>
				</div>
			</div>
			<div class="row">
				<div class="col-6" v-for="(device, index) in sortedInhalers" :key="index">
					<template>
						<device-card
							:device="device"
							:selected="selectedDevice && selectedDevice == device"
							@click.native="$store.dispatch('inhaler/setSelection', getDeviceIndex(device))">
						</device-card>
					</template>
				</div>
			</div>
      <div class="row">
          <div class="col-12">
            <p class="lightblueTxt text-center mt-4 small-txt"><strong>{{ $t('devices.pMDI') }}:</strong> {{ $t('devices.pmdi_description') }}, <strong>{{ $t('devices.SMI') }}:</strong> {{ $t('devices.smi_description') }}, <strong>{{ $t('devices.DPI') }}:</strong> {{ $t('devices.dpi_description') }}</p>
          </div>  
        </div>
      <div class="row">
				<div class="col-12">
          <button class="btn blueBtn2 mt-4" @click="handleClickEvent()">{{ $t('main.select_inhaler.test_again') }}</button>
				</div>
			</div>	
		</div>
	</section>
</template>
<script>
import { mapGetters } from 'vuex'
// import carousel from 'vue-owl-carousel'
//import _ from 'lodash'
import DeviceCard from '~/components/DeviceCard.vue'
import { deviceList } from '~/data/device-list.js'

export default {
  components: {
    DeviceCard,
  },
  computed: {
    ...mapGetters({
      devices: 'inhaler/devices',
      selectedDevice: 'inhaler/selectedDevice',
      isPWA: 'inhaler/isPWA',
      maxFlowDampened: 'inhaler/maxFlowDampened',
    }),
    sortedInhalers() {
      return this.deviceList.slice().sort((a, b) => {
        const maxFlowA = this.maxFlowDampened; // Calculate maxFlow for inhaler 'a'
        const maxFlowB = this.maxFlowDampened; // Calculate maxFlow for inhaler 'b'

        if (maxFlowA >= a.optimalMin && maxFlowA <= a.optimalMax) {
          if (maxFlowB >= b.optimalMin && maxFlowB <= b.optimalMax) {
            return maxFlowA - maxFlowB; // Both are within optimal range
          } else {
            return -1; // 'a' is within the range, 'b' is not
          }
        } else if (maxFlowB >= b.optimalMin && maxFlowB <= b.optimalMax) {
          return 1; // 'b' is within the range, 'a' is not
        } else {
          return maxFlowA - maxFlowB; // Both are outside the optimal range
        }
      });
    },
  },
  data() {
    return {
      deviceList
    };
  },

  mounted() {
    //this.debouncedCarouselRefresh = _.debounce(this.carouselRefresh, 250)
  },

  watch: {
    selectedDevice() {
      // manually set active class to carousel item
      
      const items = document.querySelectorAll('.owl-item .device-card');
      items.forEach((item) => {
        item.classList.remove('selected')
      })

      const selectedItems = document.querySelectorAll('.owl-item .device-card[data-device-id="'+ this.getDeviceIndex(this.selectedDevice) +'"]')
      selectedItems.forEach((item) => {
        item.classList.add('selected')
      })
      
    },
  },

  methods: {
    async start() {
      // await this.$store.dispatch('inhaler/fetchAudioStream')
      this.$router.push('/start')
    },

    filteredDevices(name) {
      return this.devices.filter((device) => {
        return device.name == name
      })
    },

    getDeviceIndex(device) {
      let index = -1
      this.devices.forEach((item, i) => {
        if (item == device) {
          index = i
          return false
        }
      })
      return index
    },

    onChanged(event) {

      // check if event is not triggered by the following
      if (event.property.name != "settings") {
        //this.debouncedCarouselRefresh()
      }
        
    },

    carouselRefresh() {
        this.$refs.carousel[0].trigger('refresh.owl.carousel')
    },

    handleClickEvent() {
      this.$router.push('/main/countdown');
    }

  },
}

</script>