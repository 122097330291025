<template>
  <div class="form-check blue_gradient" :style="'background: url('+isOptimal(device.optimalMin,device.optimalMax,device.image,'background')+');'">
    <span class="form-check-input" :style="'background: url('+isOptimal(device.optimalMin,device.optimalMax,device.image,'result_icon')+');'"></span>
    <label class="form-check-label">
      <div class="row">
        <div class="col-4">
          <div class="form-checkImg">
            <img class="d-block img-fluid" :src="isOptimal(device.optimalMin, device.optimalMax, device.image,'device')">
          </div>
        </div>
        <div class="col-8">
          <div class="form-checkTxt">
            <h6 :style="isOptimal(device.optimalMin,device.optimalMax,device.image,'font_color')">{{ $t(`devices.${device.name}`) }}</h6>
          </div>  
        </div>
      </div>  
    </label>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  export default {
    computed: {
      ...mapGetters({
        maxFlowDampened: 'inhaler/maxFlowDampened',
      }),
    },
    methods: {
      isOptimal(optimalMin,optimalMax,deviceImage,imageType) {
        let image;
        let maxFlowDampened = this.maxFlowDampened;
        if(optimalMin <= maxFlowDampened && maxFlowDampened <= optimalMax) {
          if(imageType == 'background') {
            image = 'images/purple-bg.png';
          } else if(imageType == 'device') {
            image = 'images/'+deviceImage+'-purple.png';
          } else if(imageType == 'result_icon') {
            image = 'images/check.png';
          } else if(imageType == 'font_color') {
            image = 'color:#6870b3;font-weight:300';
          }
          return image;
          //return 'images/thumbs-up.png'; 
        } else {
          if(imageType == 'background') {
            image = 'images/gray-bg.png';
          } else if(imageType == 'device') {
            image = 'images/'+deviceImage+'-gray.png';
          } else if(imageType == 'result_icon') {
            image = 'images/cross.png';
          } else if(imageType == 'font_color') {
            image = 'color:#6e6e76;font-weight:300';
          }
          return image;
          //return 'images/'+color+'-cross.png';
          //return 'images/thumbs-down.png';
        }
      }
    },
    props: [
      'device',
      'selected',
    ],
  }
</script>
